import React from "react";

const Aboutus = () => {
  return (
    <>
      <section
        style={{
          backgroundImage: `url("https://ik.imagekit.io/nboj7z3sl/OurBestSerBanner.18e034c4dd73e4cf5b58_3HPPVvCXa.png")`,
        }}
        className=""
      >
        <section class="pt-20 lg:pt-[120px] pb-12 lg:pb-[90px] overflow-hidden">
          <div class="container px-4 mx-auto">
            <div class="flex flex-wrap justify-between items-center">
              <div class="w-full lg:w-6/12 px-4">
                <div class="flex items-center -mx-3 sm:-mx-4">
                  <div class="w-full xl:w-1/2 px-3 sm:px-4">
                    <div class="py-3 sm:py-4">
                      <img
                        src="https://cdn.tailgrids.com/1.0/assets/images/services/image-1.jpg"
                        alt=""
                        class="rounded-2xl w-full"
                      />
                    </div>
                    <div class="py-3 sm:py-4">
                      <img
                        src="https://cdn.tailgrids.com/1.0/assets/images/services/image-2.jpg"
                        alt=""
                        class="rounded-2xl w-full"
                      />
                    </div>
                  </div>
                  <div class="w-full xl:w-1/2 px-3 sm:px-4">
                    <div class="my-4 relative z-10">
                      <img
                        src="https://cdn.tailgrids.com/1.0/assets/images/services/image-3.jpg"
                        alt=""
                        class="rounded-2xl w-full"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-1/2 xl:w-5/12 px-4">
                <div class="mt-10 lg:mt-0">
                  <span class="font-semibold text-lg text-primary mb-2 block">
                    About us
                  </span>
                  <h2 class="font-bold text-3xl sm:text-4xl text-black mb-8">
                    Make your customers happy by giving services.
                  </h2>
                  <p class="text-base text-body-color mb-8">
                    The word ‘Vcana’ means ‘Promise’. Vcana Global is a CANDID
                    IT Consulting / Digital Marketing company with a host of
                    services to offer under one umbrella. Founded in the year
                    2019 by a couple of IT enthusiasts who believe in making
                    something big. Today, Vcana Global is serving more than 100+
                    Clients across the globe. We are a bunch of interesting
                    people who work day in and day out to achieve the goals of
                    our clients.
                  </p>
                  <p class="text-base text-body-color mb-12">
                    Our core work strategy is instituted on a meticulous
                    understanding of every client’s needs, ever changing work
                    requirements and overall budget constraints. By using the
                    expertise and profound technological background, Vcana
                    creates an exhaustive web strategy for digital
                    transformation of your organization as per your business
                    objectives. You can count on our IT consulting and in-depth
                    market knowledge that will give you an edge over others and
                    help you program and digitize operations, optimize company’s
                    portfolio, and realize your mission and vision.
                  </p>
                  <button
                    class="
                  py-4
                  px-10
                  lg:px-8
                  xl:px-10
                  inline-flex
                  items-center
                  justify-center
                  text-center text-white text-base
                  bg-[#E50403]
                  hover:bg-opacity-90
                  font-normal
                  rounded-lg
                  "
                  >
                    Get Started
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Aboutus;
