import React from "react";
import { AiFillControl } from "react-icons/ai";
import { HiUsers } from "react-icons/hi";
import { GiArrowCursor } from "react-icons/gi";
import { MdAccessTimeFilled, MdEmail, MdBackup } from "react-icons/md";
import { FiHardDrive } from "react-icons/fi";
import { BsFillAwardFill } from "react-icons/bs";
import { FaLock } from "react-icons/fa";
import HostingPricingTable from "./HostingPricingTable";

const Hosting = () => {
  return (
    <>
      <section class="relative py-8 overflow-hidden">
        <div class="container mx-auto px-4">
          <div class="flex  border-b bg-white rounded-md  flex-wrap items-center">
            <div class="w-full lg:w-1/2 p-6">
              <div class="lg:max-w-lg">
                <h2 class="mb-6 font-heading font-bold text-5xl text-gray-900">
                  Web Hosting Service
                </h2>
                <p class="mb-12 text-lg text-gray-600">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi
                  fuga voluptatibus consectetur, adipisci sint aliquid harum
                  quod quam natus cumque!
                </p>
                <div className="flex space-x-3">
                  <button className="bg-[#E50403] text-white p-2 px-5 rounded-md">
                    Get Started
                  </button>
                  <span className="">
                    <h2 className="font-semibold">Starting at Only</h2>
                    <h2>$00.00/Mo*</h2>
                  </span>
                </div>
              </div>
            </div>
            <div class="w-full lg:w-1/2 p-6">
              <div class="bg-gradient-orange flex justify-center md:justify-end  rounded-3xl">
                <img
                  class="relative md:h-96 rounded  transform transition ease-in-out duration-500"
                  src="https://www.blogtyrant.com/wp-content/uploads/2012/03/choose-best-blog-hosting.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        {/* We Get it. */}
        <div class="py-12 bg-white">
          <div class="container mx-auto px-4 sm:px-6 lg:px-8">
            <div class="lg:text-center">
              <h2 class="text-2xl text-[#E50403] font-semibold">
                We Get It. It's more than a Website
              </h2>

              <p class="mt-4 max-w-2xl text-lg text-gray-500 lg:mx-auto">
                It's Your mission, your brand, your business - That's why
                DreamHost is passionate about doing things right to ensure your
                success.
              </p>
            </div>
            <div class="mt-16">
              <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-10">
                {FeatureData.map((e, index) => {
                  return (
                    <div class="relative hover:bg-yellow-100 cursor-pointer md:p-4 rounded-lg">
                      <dt>
                        <div class="absolute flex items-center justify-center h-12 w-14 rounded-md bg-[#E50403] text-white">
                          {/* <!-- Heroicon name: outline/globe-alt --> */}
                          <span class="text-2xl">{e.icon}</span>
                        </div>
                        <p
                          key={index}
                          class="ml-16 text-lg leading-6 font-medium text-gray-900"
                        >
                          {e.title}
                        </p>
                      </dt>
                      <dd
                        key={index}
                        class="mt-2 ml-16 text-base text-gray-500"
                      >
                        {e.desc}
                      </dd>
                    </div>
                  );
                })}
              </dl>
            </div>
          </div>
        </div>
        {/* Pricing Table */}
        <HostingPricingTable />
      </section>
    </>
  );
};

export const FeatureData = [
  {
    icon: <AiFillControl />,
    title: "Custom Control Panel",
    desc: "Wordpress, Joomla, Drupal, Wix, Weebly, Sitecore",
  },
  {
    icon: <HiUsers />,
    title: "Real-Life Huma  Beings",
    desc: "Django, Ruby on Rails, Angular, React JS, ASP.Net, CakePhP",
  },
  {
    icon: <GiArrowCursor />,
    title: "1-Click Installer",
    desc: "jQuery, Bootstrap, Semantic-UI, Foundation, CSS/HTML",
  },
  {
    icon: <MdAccessTimeFilled />,
    title: "100% Uptime Guarantee",
    desc: "ProtoPie, Framer, Affinity Designer, Photoshop, Illustrator",
  },
  {
    icon: <FiHardDrive />,
    title: "Solid State Drives(SSDs)",
    desc: "Elementor, VisualComposer, Yoast, Everest Forms, WP Mush",
  },
  {
    icon: <BsFillAwardFill />,
    title: "Award-Winning Support",
    desc: "PhP, Python, Ruby, HTML5, CSS3, SQL, NodeJS, Javascript",
  },
  {
    icon: <MdEmail />,
    title: "Unlimited Email @ Your Domain",
    desc: "MongoDB, Redis, PostgreSQL, MySQL, Oracle, SQL Server",
  },
  {
    icon: <FaLock />,
    title: "Free SSL Security",
    desc: "Xampp, Mamp, WampServer, Laragon, Vagrant",
  },
  {
    icon: <MdBackup />,
    title: "Automated Backups",
    desc: "Zapier, Runscope, Postman, SoapUI    ",
  },
];

export default Hosting;
