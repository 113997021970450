import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Baseurl } from "../../Baseurl";
import { Loader } from "../../Layout/Loader";

export const BlogProp = ({ Bloglist, text, loader }) => {
  const navigate = useNavigate();
  console.log(Bloglist, "aa rha hai");
  return (
    <section class="text-gray-600 my-4 body-font">
      <div class="container px-5  mx-auto">
        <h2 className="text-center text-3xl font-semibold text-black py-14">
          Latest Blog
        </h2>
        {loader ? (
          <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 -m-4">
            {Bloglist?.map((e, index) => {
              return (
                // <div
                //   onClick={() => {
                //     navigate(
                //       `/blog/${e?.title?.replace(/\s+/g, "-").toLowerCase()}`,
                //       { state: e }
                //     );
                //   }}
                //   class="m-2"
                // >
                //   <div class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                //     <img
                //       class="lg:h-48 md:h-36 w-full object-cover object-center"
                //       src={`https://usd-services.herokuapp.com/${e.image}`}
                //       alt="blog"
                //     />
                //     <div class="p-6">
                //       {/* <h2 class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
                //       {e.cat}
                //     </h2> */}
                //       <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
                //         {e?.name?.slice(0, 30) + "..."}
                //       </h1>
                //       <p class="leading-relaxed mb-3 text-sm">
                //         {e?.long_desc?.slice(0, 30)}
                //       </p>
                //       <div class="flex items-center flex-wrap ">
                //         <a
                //           onClick={() => {
                //             navigate(
                //               `/blog/${e?.title
                //                 .replace(/\s+/g, "-")
                //                 .toLowerCase()}`,
                //               { state: e }
                //             );
                //           }}
                //           class="text-indigo-500 inline-flex items-center md:mb-2 text-xs lg:mb-0"
                //         >
                //           Learn More
                //           {/* <svg
                //           class="w-4 h-4 ml-2"
                //           viewBox="0 0 24 24"
                //           stroke="currentColor"
                //           strokeWidth="2"
                //           fill="none"
                //           strokeLinecap="round"
                //           strokeLineJoin="round"
                //         >
                //           <path d="M5 12h14"></path>
                //           <path d="M12 5l7 7-7 7"></path>
                //         </svg> */}
                //         </a>
                //       </div>
                //     </div>
                //   </div>
                // </div>
                <div
                  key={index}
                  onClick={() => {
                    navigate(
                      `/blog/${e?.title.replace(/\s+/g, "-").toLowerCase()}`,
                      { state: e }
                    );
                  }}
                  class="  cursor-pointer   bg-white rounded-lg shadow-xl mx-auto mb-4 lg:mb-10"
                >
                  <div class="rounded overflow-hidden mb-3">
                    <img
                      src={`https://usd-services.herokuapp.com/${e.image}`}
                      alt=""
                      class="w-full rounded-lg h-52"
                    />
                  </div>

                  <div className="p-3">
                    {/* <span
                    class="
                     bg-[#6CC000]
                     rounded
                     inline-block
                     text-center
                     py-1
                     px-4
                     text-xs
                     leading-loose
                     font-semibold
                     text-white
                     mb-5
                     "
                  >
                    {e.category}
                  </span>
                  <span
                    class="
                     bg-[#6CC000]
                     rounded
                     inline-block
                     text-center
                     py-1
                     px-4
                     mx-4
                     text-xs
                     leading-loose
                     font-semibold
                     text-white
                     mb-5
                     "
                  >
                    {e.date}
                  </span> */}
                    <h3>
                      <h3 class=" font-semibold text-xl sm:text-2xl lg:text-xl  mb-4 inline-block text-black hover:text-primary">
                        {e?.name?.slice(0, 30) + "..."}
                      </h3>
                    </h3>
                    <p class="text-sm text-gray-400">
                      {e?.long_desc?.slice(0, 30)}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="flex justify-center items-center h-[50vh]">
            <Loader />
          </div>
        )}
      </div>
    </section>
  );
};

const Blog = () => {
  const [blogData, setBlogData] = useState([]);
  const [loader, setLoader] = useState(false);

  const getBlog = async () => {
    const url = `${Baseurl}web/get-blogs`;
    try {
      const res = await axios.get(url);
      setBlogData(res.data.data);
      setLoader(true);
    } catch (error) {}
  };

  console.log(blogData, "aajjhg");
  useEffect(() => {
    getBlog();
  }, []);
  return (
    <>
      <BlogProp Bloglist={blogData} loader={loader} />
    </>
  );
};

export default Blog;
