import { MdCall, MdEmail } from "react-icons/md";
import { FiMapPin } from "react-icons/fi";

const Address = [
  {
    icon: <FiMapPin />,
    title: "Our Head Office",
    address: "USD Services , Hadapsar , Pune , Maharashatra, India- 412308",
  },
  {
    icon: <MdCall />,
    title: "Call for Help",
    address: "+91-7588223343",
  },
  {
    icon: <MdEmail />,
    title: "Email for Information",
    address: "usdservices@gmail.com",
  },
];

export default Address;
