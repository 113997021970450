import React from "react";
import why from "../../Assets/why.png";
import { useNavigate } from "react-router-dom";
import WhyChooseUs from "../Home/WhyChooseUs";
import Team from "../Home/Team";
import Stat from "../Home/Stat";

const About = () => {
  const navigate = useNavigate();
  return (
    <>
      <section class=" ">
        <div class="container px-6 py-16 mx-auto">
          <div class="items-center flex flex-col-reverse md:flex-row gap-10 md:gap-0 lg:flex">
            <div class="w-full lg:w-2/4">
              <div class="lg:max-w-lg">
                <h1 class="text-2xl font-semibold text-gray-800 uppercase text-black tracking-wide lg:text-5xl">
                  25 Year's of Experience
                </h1>
                <p class="mt-2 text-gray-700 dark:text-gray-400">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Porro beatae error laborum ab amet sunt recusandae? Reiciendis
                  natus perspiciatis optio.
                </p>
                <button
                  onClick={() => navigate("/contact-us")}
                  class="w-full px-6 py-3 mt-6 text-xs font-medium text-white uppercase transition-colors duration-200 transform bg-[#E50403] rounded-md lg:w-auto hover:bg-blue-500 focus:outline-none focus:bg-blue-500"
                >
                  Contact us
                </button>
              </div>
            </div>

            <div class="flex items-center justify-center w-full mt-6 lg:mt-0 lg:w-2/4">
              <img
                class="md:h-96 rounded-full lg:max-w-2xl"
                src={why}
                alt="Catalogue-pana.svg"
              />
            </div>
          </div>
        </div>
      </section>
      <Stat />
      <WhyChooseUs />
      <Team />
    </>
  );
};

export default About;
