import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FiSearch, FiEdit } from "react-icons/fi";
import { CgNotes } from "react-icons/cg";
import { GoGraph } from "react-icons/go";
import { MdEmail } from "react-icons/md";
import { TbAffiliate } from "react-icons/tb";
import { HiIdentification, HiSpeakerphone, HiEmojiHappy } from "react-icons/hi";
import { GiBrickWall } from "react-icons/gi";
import { FaUsers } from "react-icons/fa";
import { IoAnalyticsOutline } from "react-icons/io5";
import Stat from "../Home/Stat";

const DigitalMarketing = () => {
  return (
    <>
      <section>
        <div className="">
          {/* Hero Web Development */}
          <div className="container mx-auto">
            <div className="md:flex py-10 border-b px-4 md:px-0 justify-between">
              <div className="flex flex-col justify-center space-y-5">
                <h2 className="text-5xl font-semibold">Scale Your Business</h2>
                <h3 className="text-xl">Bring Your Business Online</h3>
                <span className="flex space-x-5 items-center">
                  <input
                    placeholder="Enter Email Here..."
                    type="text"
                    className="bg-gray-100 border placeholder:text-xs rounded-md p-1.5"
                    name=""
                    id=""
                  />
                  <h4>OR</h4>
                  <Link
                    to={() =>
                      (window.location.href = "https://wa.me/+917723970543")
                    }
                  >
                    <FaWhatsapp className="text-xl cursor-pointer" />
                  </Link>
                </span>
                <button className="bg-[#E50403] w-36 p-2 rounded text-white">
                  Submit
                </button>
              </div>
              <img
                className="md:w-2/4 md:p-3 mt-10 md:mt-0 rounded-3xl"
                src="https://www.laudco.com/wp-content/uploads/2022/06/2020-google-Ideas-to-Increase-Business-Sale-Through-Digital-Marketing.jpg"
                alt=""
              />
            </div>
          </div>
          {/* Marketing Service */}
          <div class="py-12 container mx-auto bg-white">
            <div class="container mx-auto px-4 sm:px-6 lg:px-8">
              <div class="lg:text-center">
                <h2 class="text-lg text-indigo-600 font-semibold">
                  Our Services
                </h2>
                <p class="mt-2 text-3xl leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl sm:tracking-tight">
                  DIGITAL MARKETING SERVICES
                </p>
                <p class="mt-4 text-lg text-gray-500 lg:mx-auto">
                  Vcana Global understands your marketing needs and helps you
                  succeed by bringing strategies, expertise and execution to the
                  areas that you require the most. From workplace community to
                  marketing performance and company's growth, we have been lucky
                  enough to be recognized for some of the most sought-after
                  successes in the business realm.
                </p>
              </div>

              <div class="mt-16">
                <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-10">
                  {MarketingData.map((e, index) => {
                    return (
                      <div class="relative hover:bg-yellow-100 cursor-pointer md:p-4 rounded-lg">
                        <dt>
                          <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                            {/* <!-- Heroicon name: outline/globe-alt --> */}
                            <span className="text-2xl">{e.icon}</span>
                          </div>
                          <p
                            key={index}
                            class="ml-16 text-lg leading-6 font-medium text-gray-900"
                          >
                            {e.title}
                          </p>
                        </dt>
                        <div
                          key={index}
                          class="mt-2 ml-16 text-base text-gray-500"
                        >
                          {e.desc}
                        </div>
                      </div>
                    );
                  })}
                </dl>
              </div>
            </div>
          </div>
          {/* Stat */}
          <Stat />
        </div>
      </section>
    </>
  );
};
export const MarketingData = [
  {
    title: "SEARCH ENGINE OPTIMIZATION (SEO)",
    desc: "Within 3-4 months your website will be on first page of search engines. Try us today.",
    icon: <FiSearch />,
  },
  {
    title: "REPUTATION MANAGEMENT",
    desc: "Building trust, credibility, providing better insights & increasing sales along with online visibility. Helping clients stay ahead of competitors.",
    icon: <CgNotes />,
  },
  {
    title: "CONVERSION OPTIMIZATION",
    desc: "Optimizing conversion rate is an essential part of Digital Marketing Strategy . Vcana Global will take care of this for you.",
    icon: <GoGraph />,
  },
  {
    title: "EMAIL MARKETING",
    desc: "Email marketing is a powerful tool that can be used to build brand loyalty, gain insight into your customer behavior and drive sales. Let us help you get started!",
    icon: <MdEmail />,
  },
  {
    title: "CONTENT MARKETING",
    desc: "Companies that use content marketing can get six times more lead conversions compared to their competitors. Try us for this.",
    icon: <FiEdit />,
  },
  {
    title: "AFFILIATE MARKETING",
    desc: "Affiliate Marketing is a process of earning commission by promoting other people’s or company’s products. You can count on us for such services.",
    icon: <TbAffiliate />,
  },
  {
    title: "SOCIAL MEDIA MANAGEMENT",
    desc: "We create and manage top-performing social media campaigns / accounts / posts for our clients. This covers all Social Media platforms.",
    icon: <HiIdentification />,
  },
  {
    title: "LEAD GENERATION (B2B - B2C)",
    desc: "Maximize your sales B2B/B2C by deploying Vcana Global for your lead generation campaigns. Sit back and relax, let us perform it for you.",
    icon: <HiEmojiHappy />,
  },
  {
    title: "PAY PER CLICK SERVICES (PPC)",
    desc: "We offer PPC services with conversion in mind. We make sure we deliver the best. Our main focus is always on increasing profitability.",
    icon: <GiBrickWall />,
  },
  {
    title: "ORGANIC GROWTH IN FOLLOWERS COUNT",
    desc: "100% Organic followers on your Twitter & Instagram accounts. No bots, no fake accounts. See results in a week or get your money back.",
    icon: <FaUsers />,
  },
  {
    title: "SOCIAL MEDIA ADVERTISING",
    desc: "Whether you are looking to enhance your brand's SM presence or drive targeted Sales/Leads through ads, our SM advertising services can help you meet your goals.",
    icon: <HiSpeakerphone />,
  },
  {
    title: "RE-TARGETING & RE-MARKETING",
    desc: "Yes, this works! However, needs to be done in the right way. Increase leads and sales with our Re-Targeting & Re-Marketing strategies.",
    icon: <IoAnalyticsOutline />,
  },
];

export default DigitalMarketing;
