import React from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import why  from "../../Assets/why.png";

const WhyChooseUs = () => {
  return (
    <>
      <section class="relative py-8 overflow-hidden">
        <div class="container mx-auto px-4">
          <div class="flex  bg-white rounded-2xl  flex-wrap items-center">
            <div class="w-full lg:w-1/2 p-6">
              <div class="lg:max-w-lg">
                <h2 class="mb-6 font-heading font-bold text-4xl text-gray-900">
                  Why Choose Us
                </h2>
                <p class="mb-12 text-base text-gray-600">
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.
                  Exercitation veniam consequat.
                </p>
                <ul>
                  {WhyChoose.map((e) => {
                    return (
                      <li class="mb-4 space-x-5 flex items-center font-heading font-semibold text-lg text-gray-900">
                        <span>{e.icon}</span>
                        <p>{e.title}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div class="w-full lg:w-1/2 p-6">
              <div class="bg-gradient-orange flex justify-center md:justify-end  rounded-3xl">
                <img
                  class="relative md:h-96 rounded-2xl  transform transition ease-in-out duration-500"
                  src={why}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export const WhyChoose = [
  {
    icon: <BsCheckCircleFill />,
    title: "Qualified Team",
  },
  {
    icon: <BsCheckCircleFill />,
    title: "Individual Approach",
  },
  {
    icon: <BsCheckCircleFill />,
    title: "100% Success",
  },
  {
    icon: <BsCheckCircleFill />,
    title: "100% Satisfaction",
  },
];

export default WhyChooseUs;
