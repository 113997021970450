import React from 'react'
import Client from '../OurClient/Client'

const FleetManage = () => {
  return (
    <>
      <section class="relative bg-[#232323] py-8 overflow-hidden">
        <div class=" mx-auto px-4">
          <div class="flex  bg-white rounded-md  flex-wrap items-center">
            <div class="w-full lg:w-1/2 p-6">
              <div class="lg:max-w-lg">
                <h2 class="mb-6 font-heading font-bold text-5xl text-gray-900">
                  Fleet Management
                </h2>
                <p class="mb-12 text-lg text-gray-600">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quidem iusto beatae accusantium totam voluptas ipsam perferendis qui consectetur omnis minima.
                </p>
                
              </div>
            </div>
            <div class="w-full lg:w-1/2 p-6">
              <div class="bg-gradient-orange flex justify-center md:justify-end  rounded-3xl">
                <img
                  class="relative md:h-96 rounded  transform transition ease-in-out duration-500"
                  src="https://norsecorp.com/wp-content/uploads/2020/02/fleet-scaled.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <h2 className="text-center pt-16 pb-6 text-white text-5xl font-semibold">
        Fleet is softwear where you can check live location of your vehicle and also history
        </h2>
        <p className="text-center pb-10 text-gray-200">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eum, dolor.
          <br /> drive sales, and manage your day-to-day.
        </p>
      </section>
      <Client/>
    </>
  )
}

export default FleetManage
