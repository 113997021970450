import React from "react";
import Slider from "react-slick";

const PortFolioCard = () => {
  var settings = {
    arrows: false,
    slidesToShow: 4,
    speed: 3000,
    navSpeed: 3000,
    infinite: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <section>
        {/* <!-- component --> */}
        {/* <!-- This is an example component --> */}

        {/* <div class="flex items-center justify-center min-h-screen from-teal-100 via-teal-300 to-teal-500 bg-gradient-to-br"> */}
        <Slider className="" {...settings}>
          {PortFolioData.map((e, index) => {
            return (
              <div
                key={index}
                class="overflow-hidden  p-2 cursor-pointer rounded-xl relative group"
              >
                <div class="rounded-xl z-50 opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out cursor-pointer absolute from-black/80 to-transparent bg-gradient-to-t inset-x-0 -bottom-2 pt-30 text-white flex items-end">
                  <div>
                    <div class=" p-4 space-y-3 text-xl group-hover:opacity-100 group-hover:translate-y-0 translate-y-4 pb-10 transform transition duration-300 ease-in-out">
                      <div class="font-bold">{e.title}</div>

                      <div class="opacity-60 text-sm ">{e.desc}</div>
                    </div>
                  </div>
                </div>
                <img
                  alt=""
                  class="w-full  rounded-xl  group-hover:scale-110 transition duration-300 ease-in-out"
                  src={e.img}
                />
              </div>
            );
          })}
        </Slider>
        {/* </div> */}
      </section>
    </>
  );
};
const PortFolioData = [
  {
    img: "https://ik.imagekit.io/cc5n4mgg4x/image_7_n_AsbO8Zwg.jpg",
    title: "Web Development Services",
    desc: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur dolorem tempore voluptas iste veniam eum!",
  },
  {
    img: "https://ik.imagekit.io/cc5n4mgg4x/image_10_MBhcW5qhC.jpg",
    title: "Web Development Services",
    desc: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur dolorem tempore voluptas iste veniam eum!",
  },
  {
    img: "https://ik.imagekit.io/cc5n4mgg4x/image_6_I6CNXKgKbr.jpg",
    title: "Web Development Services",
    desc: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur dolorem tempore voluptas iste veniam eum!",
  },
  {
    img: "https://ik.imagekit.io/cc5n4mgg4x/image_1_2HLDqdhPne.jpg",
    title: "Web Development Services",
    desc: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur dolorem tempore voluptas iste veniam eum!",
  },
  {
    img: "https://ik.imagekit.io/cc5n4mgg4x/image_3_1-vb0GA4r.jpg",
    title: "Web Development Services",
    desc: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur dolorem tempore voluptas iste veniam eum!",
  },
  {
    img: "https://ik.imagekit.io/cc5n4mgg4x/image_12_1WFLZqZ56.jpg",
    title: "Web Development Services",
    desc: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur dolorem tempore voluptas iste veniam eum!",
  },
];

export default PortFolioCard;
