import React from "react";

const PricingTable = () => {
  return (
    <>
      <div class=" bg-gray-100 flex py-32 justify-center items-center">
        <div class="">
          <div class="text-center py-6 font-semibold">
            <h1 class="text-5xl">
              <span class="text-blue-700 tracking-wide">Flexible </span>
              <span>Plans</span>
            </h1>
            <p class="pt-6 text-xl text-gray-400 font-normal w-full px-8 md:w-full">
              Choose a plan that works best for you and
              <br /> your team.
            </p>
          </div>
          <div class="pt-24 md:mb-0 mb-10 md:space-y-0 space-y-10 md:flex ">
            {/* <!-- Basic Card --> */}
            <div class="md:w-96 p-8 bg-white text-center rounded-3xl md:pr-16 shadow-xl">
              <h1 class="text-black font-semibold text-2xl">Small</h1>
              <p class="pt-2 tracking-wide">
                <span class="text-gray-400 align-top">$ </span>
                <span class="text-3xl font-semibold">1499</span>
                {/* <span class="text-gray-400 font-medium">/ user</span> */}
              </p>
              <hr class="mt-4 border-1" />
              <div class="pt-8">
                <p class="font-semibold flex items-center text-gray-400 text-left">
                  <img
                  className="w-4"
                    src="https://ik.imagekit.io/nboj7z3sl/satisfication_ekUVj6CM5E.svg"
                    alt=""
                  />
                  <span class="pl-2">Upto 10 Pages</span>
                </p>
                <p class="font-semibold flex items-center text-gray-400 text-left pt-5">
                  <img
                  className="w-4"
                    src="https://ik.imagekit.io/nboj7z3sl/satisfication_ekUVj6CM5E.svg"
                    alt=""
                  />
                  <span class="pl-2">Customized Designs</span>
                </p>
                <p class="font-semibold flex items-center text-gray-400 text-left pt-5">
                  <img
                  className="w-4"
                    src="https://ik.imagekit.io/nboj7z3sl/satisfication_ekUVj6CM5E.svg"
                    alt=""
                  />
                  <span class="pl-2">Responsive Design</span>
                </p>
                <p class="font-semibold flex items-center text-gray-400 text-left pt-5">
                  <img
                  className="w-4"
                    src="https://ik.imagekit.io/nboj7z3sl/satisfication_ekUVj6CM5E.svg"
                    alt=""
                  />
                  <span class="pl-2">Unlimited Revision</span>
                </p>
                <p class="font-semibold flex items-center text-gray-400 text-left pt-5">
                  <img
                  className="w-4"
                    src="https://ik.imagekit.io/nboj7z3sl/satisfication_ekUVj6CM5E.svg"
                    alt=""
                  />
                  <span class="pl-2">3 Concept Logo</span>
                </p>
                <p class="font-semibold flex items-center text-gray-400 text-left pt-5">
                  <img
                  className="w-4"
                    src="https://ik.imagekit.io/nboj7z3sl/satisfication_ekUVj6CM5E.svg"
                    alt=""
                  />
                  <span class="pl-2">SEO Friendly</span>
                </p>
                <p class="font-semibold flex items-center text-gray-400 text-left pt-5">
                  <img
                  className="w-4"
                    src="https://ik.imagekit.io/nboj7z3sl/satisfication_ekUVj6CM5E.svg"
                    alt=""
                  />
                  <span class="pl-2">Q&A Services</span>
                </p>
                <p class="font-semibold flex items-center text-gray-400 text-left pt-5">
                  <img
                  className="w-4"
                    src="https://ik.imagekit.io/nboj7z3sl/satisfication_ekUVj6CM5E.svg"
                    alt=""
                  />
                  <span class="pl-2">Free Maintainance</span>
                </p>

                <div
                  // onClick={() => navigate("/contact-us")}
                  class=""
                >
                  <p class="w-full cursor-pointer py-4 bg-[#E50403] mt-8 rounded-xl text-white">
                    <span class="font-medium">Get in Touch</span>
                    <span class="pl-2 material-icons align-middle text-sm">
                      east
                    </span>
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- StartUp Card --> */}
            <div class=" md:w-80 p-8 bg-gray-900 text-center rounded-3xl text-white border-4 shadow-xl border-white transform md:scale-125">
              <h1 class="text-white font-semibold text-2xl">Medium</h1>
              <p class="pt-2 tracking-wide">
                <span class="text-gray-400 align-top">$ </span>
                <span class="text-3xl font-semibold">1999</span>
                {/* <span class="text-gray-400 font-medium">/ user</span> */}
              </p>
              <hr class="mt-4 border-1 border-gray-600" />
              <div class="pt-8">
                <p class="font-semibold flex items-center text-gray-400 text-left">
                  <img
                  className="w-4"
                    src="https://ik.imagekit.io/nboj7z3sl/satisfication_ekUVj6CM5E.svg"
                    alt=""
                  />
                  <span class="pl-2">Upto 22 Pages</span>
                </p>
                <p class="font-semibold flex items-center text-gray-400 text-left pt-5">
                  <img
                  className="w-4"
                    src="https://ik.imagekit.io/nboj7z3sl/satisfication_ekUVj6CM5E.svg"
                    alt=""
                  />
                  <span class="pl-2">Customized Design</span>
                </p>
                <p class="font-semibold flex items-center text-gray-400 text-left pt-5">
                  <img
                  className="w-4"
                    src="https://ik.imagekit.io/nboj7z3sl/satisfication_ekUVj6CM5E.svg"
                    alt=""
                  />
                  <span class="pl-2">Responsive Design</span>
                </p>
                <p class="font-semibold flex items-center text-gray-400 text-left pt-5">
                  <img
                  className="w-4"
                    src="https://ik.imagekit.io/nboj7z3sl/satisfication_ekUVj6CM5E.svg"
                    alt=""
                  />
                  <span class="pl-2">Unlimited Revision</span>
                </p>
                <p class="font-semibold flex items-center text-gray-400 text-left pt-5">
                  <img
                  className="w-4"
                    src="https://ik.imagekit.io/nboj7z3sl/satisfication_ekUVj6CM5E.svg"
                    alt=""
                  />
                  <span class="pl-2">3 Logo Concepts</span>
                </p>
                <p class="font-semibold flex items-center text-gray-400 text-left pt-5">
                  <img
                  className="w-4"
                    src="https://ik.imagekit.io/nboj7z3sl/satisfication_ekUVj6CM5E.svg"
                    alt=""
                  />
                  <span class="pl-2">Seo Friendly</span>
                </p>
                <p class="font-semibold flex items-center text-gray-400 text-left pt-5">
                  <img
                  className="w-4"
                    src="https://ik.imagekit.io/nboj7z3sl/satisfication_ekUVj6CM5E.svg"
                    alt=""
                  />
                  <span class="pl-2">Q&A Services</span>
                </p>
                <p class="font-semibold flex items-center text-gray-400 text-left pt-5">
                  <img
                  className="w-4"
                    src="https://ik.imagekit.io/nboj7z3sl/satisfication_ekUVj6CM5E.svg"
                    alt=""
                  />
                  <span class="pl-2">Content Writing Free</span>
                </p>
                <p class="font-semibold flex items-center text-gray-400 text-left pt-5">
                  <img
                  className="w-4"
                    src="https://ik.imagekit.io/nboj7z3sl/satisfication_ekUVj6CM5E.svg"
                    alt=""
                  />
                  <span class="pl-2">Free Maintainence</span>
                </p>
                <div
                  // onClick={() => navigate("/contact-us")}
                  class=""
                >
                  <p class="w-full cursor-pointer py-4 bg-[#E50403] mt-8 rounded-xl text-white">
                    <span class="font-medium">Get in Touch</span>
                    <span class="pl-2 material-icons align-middle text-sm">
                      east
                    </span>
                  </p>
                </div>
              </div>
              <div class="absolute top-4 right-4">
                <p class="bg-blue-700 font-semibold px-4 py-1 rounded-full uppercase text-xs">
                  Popular
                </p>
              </div>
            </div>
            {/* <!-- Enterprise Card --> */}
            <div class="md:w-96 p-8 bg-white text-center rounded-3xl md:pl-16 shadow-xl">
              <h1 class="text-black font-semibold text-2xl">Large</h1>
              <p class="pt-2 tracking-wide">
                <span class="text-gray-400 align-top">$ </span>
                <span class="text-3xl font-semibold">2499</span>
                <span class="text-gray-400 font-medium">/ user</span>
              </p>
              <hr class="mt-4 border-1" />
              <div class="pt-8">
                <p class="font-semibold flex items-center text-gray-400 text-left">
                  <img
                  className="w-4"
                    src="https://ik.imagekit.io/nboj7z3sl/satisfication_ekUVj6CM5E.svg"
                    alt=""
                  />
                  <span class="pl-2">Upto 50 Pages</span>
                </p>
                <p class="font-semibold flex items-center text-gray-400 text-left pt-5">
                  <img
                  className="w-4"
                    src="https://ik.imagekit.io/nboj7z3sl/satisfication_ekUVj6CM5E.svg"
                    alt=""
                  />
                  <span class="pl-2">Customized Designs</span>
                </p>
                <p class="font-semibold flex items-center text-gray-400 text-left pt-5">
                  <img
                  className="w-4"
                    src="https://ik.imagekit.io/nboj7z3sl/satisfication_ekUVj6CM5E.svg"
                    alt=""
                  />
                  <span class="pl-2">Responsive Design</span>
                </p>
                <p class="font-semibold flex items-center text-gray-400 text-left pt-5">
                  <img
                  className="w-4"
                    src="https://ik.imagekit.io/nboj7z3sl/satisfication_ekUVj6CM5E.svg"
                    alt=""
                  />
                  <span class="pl-2">Unlimited Revision</span>
                </p>
                <p class="font-semibold flex items-center text-gray-400 text-left pt-5">
                  <img
                  className="w-4"
                    src="https://ik.imagekit.io/nboj7z3sl/satisfication_ekUVj6CM5E.svg"
                    alt=""
                  />
                  <span class="pl-2">5 Concept Logo</span>
                </p>
                <p class="font-semibold flex items-center text-gray-400 text-left pt-5">
                  <img
                  className="w-4"
                    src="https://ik.imagekit.io/nboj7z3sl/satisfication_ekUVj6CM5E.svg"
                    alt=""
                  />
                  <span class="pl-2">SEO Friendly</span>
                </p>
                <p class="font-semibold flex items-center text-gray-400 text-left pt-5">
                  <img
                  className="w-4"
                    src="https://ik.imagekit.io/nboj7z3sl/satisfication_ekUVj6CM5E.svg"
                    alt=""
                  />
                  <span class="pl-2">Q&A Services</span>
                </p>
                <p class="font-semibold flex items-center text-gray-400 text-left pt-5">
                  <img
                  className="w-4"
                    src="https://ik.imagekit.io/nboj7z3sl/satisfication_ekUVj6CM5E.svg"
                    alt=""
                  />
                  <span class="pl-2">Free Maintainance</span>
                </p>

                <div
                  //   onClick={() => {
                  //     navigate("/contact-us");
                  //   }}
                  class=""
                >
                  <p class="w-full cursor-pointer py-4 bg-[#E50403] mt-8 rounded-xl text-white">
                    <span class="font-medium">Get in Touch</span>
                    <span class="pl-2 material-icons align-middle text-sm">
                      east
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingTable;
