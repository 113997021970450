import React from "react";
import why from "../../Assets/why.png";
import { Card } from "../Home/Common";
import { WhyChoose } from "../Home/WhyChooseUs";
import Client from "../OurClient/Client";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import OurPortFolio from "../Home/Portfolio/OurPortFolio";

const WebDevelopment = () => {
  return (
    <>
      <section className="container mx-auto">
        {/* Hero Web Development */}
        <div className="md:flex py-10 border-b px-4 md:px-0 justify-between">
          <div className="flex flex-col justify-center space-y-5">
            <h2 className="text-5xl font-semibold">Scale Your Business</h2>
            <h3 className="text-xl">Bring Your Business Online</h3>
            <span className="flex space-x-5 items-center">
              <input
                placeholder="Enter Email Here..."
                type="text"
                className="bg-gray-100 border placeholder:text-xs rounded-md p-1.5"
                name=""
                id=""
              />
              <h4>OR</h4>
              <Link
                to={() =>
                  (window.location.href = "https://wa.me/+917723970543")
                }
              >
                <FaWhatsapp className="text-xl cursor-pointer" />
              </Link>
            </span>
            <button className="bg-[#E50403] w-36 p-2 rounded text-white">
              Submit
            </button>
          </div>
          <img
            className="md:w-2/4 md:p-3 mt-10 md:mt-0 rounded-3xl"
            src="https://www.spinutech.com/webres/Image/web-design-development/articles/Web%20Dev-Blog.png"
            alt=""
          />
        </div>
        {/* Web Development Process */}
        <section class="relative bg-white md:py-44 overflow-hidden">
          <div class="container mx-auto px-4">
            <div class="flex flex-wrap -m-6">
              <div class="w-full lg:w-1/2 p-6">
                <div class="">
                  <h2 class="mb-6 font-heading font-bold text-3xl md:text-6xl text-gray-900">
                    Web Development Process
                  </h2>
                  <p class="mb-20 text-base text-gray-600">
                    Vcana Global has a well equipped web development lab in
                    place which is run by highly experienced team of designers,
                    developers, testers and project managers. Our team of
                    experts has the knowledge of the latest technologies as per
                    the current industry standards and keep themselves updated
                    about the latest trends. We as a team put focus on our
                    clients requirements and make sure that the project is
                    delivered with the utmost satisfaction.
                  </p>
                  <ul className="grid grid-cols-2">
                    <li class="mb-4 flex space-x-3 items-center font-heading font-semibold text-lg text-gray-900">
                      <img
                        src="https://ik.imagekit.io/cc5n4mgg4x/Understand_OxCzVGht3.png"
                        alt=""
                      />
                      <p>UNDERSTAND REQUIREMENT</p>
                    </li>
                    <li class="mb-4 flex items-center font-heading font-semibold space-x-3 text-lg text-gray-900">
                      <img
                        src="https://ik.imagekit.io/cc5n4mgg4x/Design_P5MPn4UIK.png"
                        alt=""
                      />
                      <p>DESIGN THE WIREFRAME</p>
                    </li>
                    <li class="mb-4 flex items-center font-heading font-semibold space-x-3 text-lg text-gray-900">
                      <img
                        src="https://ik.imagekit.io/cc5n4mgg4x/Code_d4ifjS96N.png"
                        alt=""
                      />
                      <p>CODE AND DEVELOP</p>
                    </li>
                    <li class="mb-4 flex items-center font-heading font-semibold space-x-3 text-lg text-gray-900">
                      <img
                        src="https://ik.imagekit.io/cc5n4mgg4x/Bug_hx4HBCD5E.png"
                        alt=""
                      />
                      <p>TESTING & FIXING OF BUGS</p>
                    </li>
                    <li class="mb-4 flex items-center font-heading font-semibold space-x-3 text-lg text-gray-900">
                      <img
                        src="https://ik.imagekit.io/cc5n4mgg4x/Deploy_ZsXKOo_ui.png"
                        alt=""
                      />
                      <p>DEPLOYMENT</p>
                    </li>
                    <li class="mb-4 flex items-center font-heading font-semibold space-x-3 text-lg text-gray-900">
                      <img
                        src="https://ik.imagekit.io/cc5n4mgg4x/Maintainance_pUGGLHfdW.png"
                        alt=""
                      />
                      <p>MAINTENANCE</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="w-full lg:w-1/2 ">
                <div class="bg-gradient-orange mx-auto max-w-xl rounded-3xl">
                  <img
                    class="relative top-10 mx-auto rounded-lg transition ease-in-out duration-500"
                    draggable={false}
                    src="https://ik.imagekit.io/cc5n4mgg4x/Web_Development_Company_in_Delaware_pKzJoeg4R.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Web Feature */}
        <div class="py-12 bg-white">
          <div class="container mx-auto px-4 sm:px-6 lg:px-8">
            <div class="lg:text-center">
              <h2 class="text-lg text-indigo-600 font-semibold">We Use</h2>
              <p class="mt-2 text-3xl leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl sm:tracking-tight">
                TOOL AND TECHNOLOGIES
              </p>
              <p class="mt-4 max-w-2xl text-lg text-gray-500 lg:mx-auto">
                Lorem ipsum dolor sit amet consect adipisicing elit. Possimus
                magnam voluptatum cupiditate veritatis in accusamus quisquam.
              </p>
            </div>

            <div class="mt-16">
              <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-10">
                {FeatureData.map((e, index) => {
                  return (
                    <div class="relative hover:bg-yellow-100 cursor-pointer md:p-4 rounded-lg">
                      <dt>
                        <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                          {/* <!-- Heroicon name: outline/globe-alt --> */}
                          <svg
                            class="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                            />
                          </svg>
                        </div>
                        <p
                          key={index}
                          class="ml-16 text-lg leading-6 font-medium text-gray-900"
                        >
                          {e.title}
                        </p>
                      </dt>
                      <dd
                        key={index}
                        class="mt-2 ml-16 text-base text-gray-500"
                      >
                        {e.desc}
                      </dd>
                    </div>
                  );
                })}
              </dl>
            </div>
          </div>
        </div>
        {/* Why Collabrate */}
        <div className="">
          <div className=" text-center py-16 space-y-4">
            <h2 className="text-2xl text-black md:text-5xl font-bold">
              WHY COLLABORATE WITH USD Service?
            </h2>
            <p>
              Let our experts handle all your e-commerce needs and give you the
              support that your business deserves. With our top-notch
              development services and innovative marketing techniques, we will
              help you to kick-start your next growth curve.
            </p>
          </div>
        </div>

        <div class=" py-20 space-y-20 px-10 ">
          <div class={style.whyColGrid}>
            <div
              class={style.gridDown}
              x-data="{ circumference: 50 * 2 * Math.PI, percent: 80 }"
            >
              <div class={style.svgUp}>
                <svg
                  class="w-32 h-32 transform translate-x-1 translate-y-1"
                  x-cloak
                  aria-hidden="true"
                >
                  <circle
                    class="text-gray-300"
                    stroke-width="10"
                    stroke="currentColor"
                    fill="transparent"
                    r="50"
                    cx="60"
                    cy="60"
                  />
                  <circle
                    class="text-blue-600"
                    stroke-width="10"
                    stroke-dasharray="circumference"
                    stroke-dashoffset="circumference - percent / 100 * circumference"
                    stroke-linecap="round"
                    stroke="currentColor"
                    fill="transparent"
                    r="50"
                    cx="60"
                    cy="60"
                  />
                </svg>
                <span
                  class="absolute text-2xl text-blue-700"
                  // x-text={`${percent}%`}
                >
                  93%
                </span>
              </div>
              <p class="ml-10 font-medium text-gray-600 sm:text-xl">UI/UX</p>

              <span class="ml-auto text-3xl font-medium text-blue-600 hidden sm:block">
                <BsFillPatchCheckFill />
              </span>
            </div>
            <div
              class="flex items-center flex-wrap max-w-md px-10 bg-white border shadow-xl rounded-2xl h-20"
              x-data="{ circumference: 50 * 2 * Math.PI, percent: 90 }"
            >
              <div class="flex items-center justify-center -m-6 overflow-hidden border bg-white rounded-full">
                <svg
                  class="w-32 h-32 transform translate-x-1 translate-y-1"
                  x-cloak
                  aria-hidden="true"
                >
                  <circle
                    class="text-gray-300"
                    stroke-width="10"
                    stroke="currentColor"
                    fill="transparent"
                    r="50"
                    cx="60"
                    cy="60"
                  />
                  <circle
                    class="text-red-600"
                    stroke-width="10"
                    // :stroke-dasharray="circumference"
                    // :stroke-dashoffset="circumference - percent / 100 * circumference"
                    stroke-linecap="round"
                    stroke="currentColor"
                    fill="transparent"
                    r="50"
                    cx="60"
                    cy="60"
                  />
                </svg>
                <span
                  class="absolute text-2xl text-red-700"
                  // x-text="`${percent}%`"
                >
                  95%
                </span>
              </div>
              <p class="ml-10 font-medium text-gray-600 sm:text-xl">
                Fully Customize
              </p>

              <span class="ml-auto text-3xl font-medium text-red-600 hidden sm:block">
                <BsFillPatchCheckFill />
              </span>
            </div>
            <div
              class="flex items-center flex-wrap max-w-md px-10 bg-white border shadow-xl rounded-2xl h-20"
              x-data="{ circumference: 50 * 2 * Math.PI, percent: 80 }"
            >
              <div class="flex items-center justify-center -m-6 overflow-hidden bg-white border rounded-full">
                <svg
                  class="w-32 h-32 transform translate-x-1 translate-y-1"
                  x-cloak
                  aria-hidden="true"
                >
                  <circle
                    class="text-gray-300"
                    stroke-width="10"
                    stroke="currentColor"
                    fill="transparent"
                    r="50"
                    cx="60"
                    cy="60"
                  />
                  <circle
                    class="text-blue-600"
                    stroke-width="10"
                    // :stroke-dasharray="circumference"
                    // :stroke-dashoffset="circumference - percent / 100 * circumference"
                    stroke-linecap="round"
                    stroke="currentColor"
                    fill="transparent"
                    r="50"
                    cx="60"
                    cy="60"
                  />
                </svg>
                <span
                  class="absolute text-2xl text-blue-700"
                  // x-text="`${percent}%`"
                >
                  98%
                </span>
              </div>
              <p class="ml-10 font-medium text-gray-600 sm:text-xl">Security</p>

              <span class="ml-auto text-3xl font-medium text-blue-600 hidden sm:block">
                <BsFillPatchCheckFill />
              </span>
            </div>
          </div>
          <div class={style.whyColGrid}>
            <div
              class={style.gridDown}
              x-data="{ circumference: 50 * 2 * Math.PI, percent: 90 }"
            >
              <div class={style.svgUp}>
                <svg
                  class="w-32 h-32 transform translate-x-1 translate-y-1"
                  x-cloak
                  aria-hidden="true"
                >
                  <circle
                    class="text-gray-300"
                    stroke-width="10"
                    stroke="currentColor"
                    fill="transparent"
                    r="50"
                    cx="60"
                    cy="60"
                  />
                  <circle
                    class="text-red-600"
                    stroke-width="10"
                    // :stroke-dasharray="circumference"
                    // :stroke-dashoffset="circumference - percent / 100 * circumference"
                    stroke-linecap="round"
                    stroke="currentColor"
                    fill="transparent"
                    r="50"
                    cx="60"
                    cy="60"
                  />
                </svg>
                <span
                  class="absolute text-2xl text-red-700"
                  // x-text="`${percent}%`"
                >
                  95%
                </span>
              </div>
              <p class="ml-10 font-medium text-gray-600 sm:text-xl">Branding</p>

              <span class="ml-auto text-3xl font-medium text-red-600 hidden sm:block">
                <BsFillPatchCheckFill />
              </span>
            </div>
            <div
              class="flex items-center flex-wrap max-w-md px-10 bg-white border shadow-xl rounded-2xl h-20"
              x-data="{ circumference: 50 * 2 * Math.PI, percent: 80 }"
            >
              <div class="flex items-center justify-center -m-6 overflow-hidden bg-white border rounded-full">
                <svg
                  class="w-32 h-32 transform translate-x-1 translate-y-1"
                  x-cloak
                  aria-hidden="true"
                >
                  <circle
                    class="text-gray-300"
                    stroke-width="10"
                    stroke="currentColor"
                    fill="transparent"
                    r="50"
                    cx="60"
                    cy="60"
                  />
                  <circle
                    class="text-blue-600"
                    stroke-width="10"
                    // :stroke-dasharray="circumference"
                    // :stroke-dashoffset="circumference - percent / 100 * circumference"
                    stroke-linecap="round"
                    stroke="currentColor"
                    fill="transparent"
                    r="50"
                    cx="60"
                    cy="60"
                  />
                </svg>
                <span
                  class="absolute text-2xl text-blue-700"
                  // x-text="`${percent}%`"
                >
                  99%
                </span>
              </div>
              <p class="ml-10 font-medium text-gray-600 sm:text-xl">
                Fully Resposive
              </p>

              <span class="ml-auto text-3xl font-medium text-blue-600 hidden sm:block">
                <BsFillPatchCheckFill />
              </span>
            </div>
            <div
              class="flex items-center flex-wrap max-w-md px-10 bg-white border shadow-xl rounded-2xl h-20"
              x-data="{ circumference: 50 * 2 * Math.PI, percent: 90 }"
            >
              <div class="flex items-center justify-center -m-6 overflow-hidden bg-white border rounded-full">
                <svg
                  class="w-32 h-32 transform translate-x-1 translate-y-1"
                  x-cloak
                  aria-hidden="true"
                >
                  <circle
                    class="text-gray-300"
                    stroke-width="10"
                    stroke="currentColor"
                    fill="transparent"
                    r="50"
                    cx="60"
                    cy="60"
                  />
                  <circle
                    class="text-red-600"
                    stroke-width="10"
                    // :stroke-dasharray="circumference"
                    // :stroke-dashoffset="circumference - percent / 100 * circumference"
                    stroke-linecap="round"
                    stroke="currentColor"
                    fill="transparent"
                    r="50"
                    cx="60"
                    cy="60"
                  />
                </svg>
                <span
                  class="absolute text-2xl text-red-700"
                  // x-text="`${percent}%`"
                >
                  95%
                </span>
              </div>
              <p class="ml-10 font-medium text-gray-600 sm:text-xl">
                SEO Friendly
              </p>

              <span class="ml-auto text-3xl font-medium text-red-600 hidden sm:block">
                <BsFillPatchCheckFill />
              </span>
            </div>
          </div>
        </div>
        {/* PortFolio */}
        <OurPortFolio />
      </section>
    </>
  );
};
export const FeatureData = [
  {
    title: "CMS",
    desc: "Wordpress, Joomla, Drupal, Wix, Weebly, Sitecore",
  },
  {
    title: "WEB DEVELOPMENT",
    desc: "Django, Ruby on Rails, Angular, React JS, ASP.Net, CakePhP",
  },
  {
    title: "FRONT END FRAMEWORK",
    desc: "jQuery, Bootstrap, Semantic-UI, Foundation, CSS/HTML",
  },
  {
    title: "WEB DESIGN & PROTOTYPING",
    desc: "ProtoPie, Framer, Affinity Designer, Photoshop, Illustrator",
  },
  {
    title: "PLUGINS",
    desc: "Elementor, VisualComposer, Yoast, Everest Forms, WP Mush",
  },
  {
    title: "PROGRAMMING LANGUAGES",
    desc: "PhP, Python, Ruby, HTML5, CSS3, SQL, NodeJS, Javascript",
  },
  {
    title: "DATABASES",
    desc: "MongoDB, Redis, PostgreSQL, MySQL, Oracle, SQL Server",
  },
  {
    title: "LOCAL DEV ENVIRONMENTS",
    desc: "Xampp, Mamp, WampServer, Laragon, Vagrant",
  },
  {
    title: "API TOOLS    ",
    desc: "Zapier, Runscope, Postman, SoapUI    ",
  },
  {
    title: "COLLABORATION TOOLS",
    desc: "Slack, Asna, Basecamp, Jira, Zoom",
  },
  {
    title: "WEB SERVERS    ",
    desc: "Nginx, Apache, IIS, Caddy",
  },
  {
    title: "CSS PROCESSORS",
    desc: "Less, Sass, Stylus    ",
  },
];
const style = {
  whyColGrid: "grid grid-cols-1 gap-20 lg:grid-cols-3 lg:gap-10",
  gridDown:
    "flex items-center flex-wrap max-w-md px-10 bg-white border shadow-xl rounded-2xl h-20",
  svgUp:
    "flex items-center justify-center -m-6 overflow-hidden bg-white border rounded-full",
};

export default WebDevelopment;
