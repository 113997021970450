import React from "react";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const navigate = useNavigate();
  return (
    <>
      <section
        style={{
          backgroundImage: `url("https://ik.imagekit.io/cc5n4mgg4x/bg-map_1GquUx3nN.jpg")`,
        }}
        className="bg-fixed"
      >
        <section className=" py-16">
          <div className="container mx-auto ">
            <div className="flex space-y-5 flex-col items-center">
              <h3 className="text-base lg:text-3xl text-gray-800 font-medium">
                Our Services
              </h3>
              <p className="text-center text-sm px-4 lg:w-3/4 text-gray-600 font-light">
                USD Services offers a variety of IT consulting and Digital
                Marketing services which are flexible, robust & reliable as per
                our client’s requirements. At Vcana, we believe in building
                brands rather than a just a company or a name. Following is the
                list of services that we provide to our clients.
              </p>
            </div>
            <div className="grid grid-cols-1  lg:grid-cols-3 py-16">
              {ServiceData.map((e, index) => {
                return (
                  <div
                    key={index}
                    className="flex border hover:shadow-md duration-500 cursor-pointer m-6 max-w-full flex-col space-y-4 p-10 bg-white shadow-2xl servicecard rounded-3xl items-center"
                  >
                    <img className="w-40" src={e.img} alt="" />
                    <h2 className="text-[20px] text-center font-bold">
                      {e.title}
                    </h2>
                    <p className="text-center font-sans text-gray-600">
                      {e.desc}
                    </p>
                    <button
                      onClick={() => {
                        navigate(`${e.route}`);
                      }}
                      className="bg-[#E50403] text-white px-5 text-sm serbtn p-2 rounded-3xl"
                    >
                      Learn More
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

const ServiceData = [
  {
    img: "https://www.q5infotech.com/wp-content/uploads/2020/09/Web-Development.jpeg",
    title: "Web Development ",
    route: "/web-development",
    desc: " website (also written as web site) is a collection of web pages and related content that is identified by a common domain name and published on at least one web server",
  },
  {
    img: "https://norsecorp.com/wp-content/uploads/2020/02/fleet-scaled.jpg",
    title: "Fleet Management",
    route: "/fleet-manage",
    desc: "Fleet Management Companies Usa. ZapMeta Offers The Overview from 6 Engines. ",
  },
  {
    img: "https://www.cloudways.com/blog/wp-content/uploads/WordPress-Website-Hacked.jpg",
    title: "Hack Site",
    route: "/site-hacked",
    desc: "In this tutorial you will learn how to hack websites, and we will introduce you to web application hacking techniques and the counter measures.",
  },
  {
    img: "https://www.blogtyrant.com/wp-content/uploads/2012/03/choose-best-blog-hosting.png",
    title: "Host",
    route: "/web-hosting",
    desc: "GigaPromo is the website  to compare Best Cloud Hosting  Services. Search and save now!  Find the lowest price for Best Cloud  Hosting Services today!",
  },
  {
    img: "https://aufaitux.com/wp-content/uploads/2020/05/UIUX-designing-1.jpg",
    title: "Ui/UX Design",
    route: "/ui-ux",
    desc: "A user experience (UX) designer works on a team to create products the provide meaningful and enjoyable experiences for users.",
  },
  {
    img: "https://limejuice.co.za/wp-content/uploads/2021/08/Lime-Juice-Website-Services-06.jpg",
    title: "Green USD",
    route: "/green-usd",
    desc: "Graphic design is the practice of composing and arranging the visual elements of a project. Designing the layout of a magazine, creating a poster for a theatre performance, ",
  },
  {
    img: "https://techtradigital.com/wp-content/uploads/2020/04/2020-google-Ideas-to-Increase-Business-Sale-Through-Digital-Marketing.jpg",
    title: "Digital Marketing",
    route: "/digital-marketing",
    desc: "At a high level, digital marketing refers to advertising delivered through digital channels such as search engines, websites, social media",
  },
  {
    img: "https://www.w3care.com/images/uploads/services/logo-banner.png",
    title: "Logo Design",
    desc: "Know your brand personality. You should have a clear idea of the brand personality you want to convey before you start designing a company logo",
  },
];
// export const ServiceData = [
//   {
//     img: "https://ik.imagekit.io/cc5n4mgg4x/Web_Development_Uyf_uu7W5n.png",
//     title: "Web Development",
//     route: "/service/web-development",
//     desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tempora sunt, aliquid provident minus, magni eos repellat rem asperiores voluptatem nisi placeat similique ipsum accusantium praesentium recusandae ad quam illo necessitatibus!",
//   },
//   {
//     img: "https://ik.imagekit.io/cc5n4mgg4x/App_Development_AgXFoln1kA.png",
//     title: "App Development",
//     desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tempora sunt, aliquid provident minus, magni eos repellat rem asperiores voluptatem nisi placeat similique ipsum accusantium praesentium recusandae ad quam illo necessitatibus!",
//   },
//   {
//     img: "https://ik.imagekit.io/cc5n4mgg4x/Appplication_Development_orpbv4Y2e.png",
//     title: "Application Development",
//     desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tempora sunt, aliquid provident minus, magni eos repellat rem asperiores voluptatem nisi placeat similique ipsum accusantium praesentium recusandae ad quam illo necessitatibus!",
//   },
//   {
//     img: "https://ik.imagekit.io/cc5n4mgg4x/Digital_Marketing_WNJgx3UpRP.png",
//     title: "Hacked site cleanup",
//     desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tempora sunt, aliquid provident minus, magni eos repellat rem asperiores voluptatem nisi placeat similique ipsum accusantium praesentium recusandae ad quam illo necessitatibus!",
//   },
//   {
//     img: "https://ik.imagekit.io/cc5n4mgg4x/Ecommerce_Development_dRNUi2wLhK.png",
//     title: "Ecommerce Development",
//     desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tempora sunt, aliquid provident minus, magni eos repellat rem asperiores voluptatem nisi placeat similique ipsum accusantium praesentium recusandae ad quam illo necessitatibus!",
//   },
//   {
//     img: "https://ik.imagekit.io/cc5n4mgg4x/Branding_5RZW84Lgnx.png",
//     title: "Branding",
//     desc: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tempora sunt, aliquid provident minus, magni eos repellat rem asperiores voluptatem nisi placeat similique ipsum accusantium praesentium recusandae ad quam illo necessitatibus!",
//   },
// ];

export default Services;
