import React from "react";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight, MdCall, MdEmail } from "react-icons/md";
import { FiMapPin, FiSend } from "react-icons/fi";

const Footer = () => {
  const link_hover =
    "hover:text-[rgb(49,170,183)] flex gap-1 items-center font-medium transition-all hover:translate-x-2";
  return (
    <>
      <section className="bg-[#13172F] shadow-xl border-t border-[#afa9a933]  py-5 text-white">
        {/* Top  Footer*/}
        <div className="container  grid lg:grid-cols-3 gap-6 sm:grid-cols-2 tracking-wider mx-auto p-3 md:px-6 ">
          {/* Newsletter */}
          <div className="">
            <h3 className="tracking-widest uppercase text-lg">Newsletter</h3>
            <div className="text-sm flex flex-col gap-1.5 mt-3">
              <span className="text-sm text-slate-200">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Corporis, cum.
              </span>
              <div className="flex mt-4 gap-2 items-center">
                <input
                  type="email"
                  className="outline-none border-b pb-3 border-[rgb(49,170,183)] shadow-lg w-72 placeholder:tracking-wider font-light bg-transparent"
                  placeholder="Email Goes Here"
                />
                <button className="text-white shadow-lg rounded-sm bg-[#E50403] hover:bg-[#ce3737af] ">
                  <FiSend className="m-2 mx-3 text-lg" />
                </button>
              </div>
            </div>
          </div>

          {/* Helpfull links */}
          <div className="">
            <h3 className="tracking-widest uppercase text-lg">
              Helpfull Links
            </h3>
            <div className="text-xs flex flex-col gap-1.5 mt-3">
              <Link to="/" className={link_hover}>
                <MdKeyboardArrowRight /> Term Of Use
              </Link>
              <Link to="/" className={link_hover}>
                <MdKeyboardArrowRight /> Privacy Policy
              </Link>
              <Link to="/" className={link_hover}>
                <MdKeyboardArrowRight /> Cookies
              </Link>
              <Link to="/" className={link_hover}>
                <MdKeyboardArrowRight /> Help
              </Link>
              <Link to="/" className={link_hover}>
                <MdKeyboardArrowRight /> FAQ'S
              </Link>
            </div>
          </div>

          {/* Head Office */}
          <div className="">
            <h3 className="tracking-widest uppercase text-lg">
              Our Head Office
            </h3>
            <div className="text-sm flex space-y-2 flex-col gap-2 md:gap-1.5 mt-3">
              <div className="flex  text-xs items-start gap-2">
                <FiMapPin className="text-xl text-[#E50403]" /> USD
                Services , Hadapsar , Pune ,<br/> Maharashatra, India- 412308
              </div>
              <a
                href="tel:+91-7588223343"
                className="flex   text-xs items-start gap-2"
              >
                <MdCall className="text-lg text-[#E50403]" />{" "}
                +91-7588223343
              </a>
              <a
                href="mailto: helpdesk@usdservices.in"
                className="flex   text-xs items-start gap-2"
              >
                <MdEmail className="text-lg text-[#E50403]" />
                helpdesk@usdservices.in
              </a>
            </div>
          </div>
        </div>
        {/* Bottom Footer */}
        <div className="container border-t border-[#afa9a933] mt-5 mx-auto tracking-wider p-3 md:px-6 ">
          <div className="text-slate-200 text-xs">
            &copy; 2022
            <span className="font-semibold px-1.5 cursor-pointer text-xs text-white">
              USD SERVICES,
            </span>
            All Rights Reserved
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
