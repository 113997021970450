import React from "react";
import { useNavigate } from "react-router-dom";
import ContactUs from "../ContactUs/ContactUs";

const UIUX = () => {
  const navigate = useNavigate();
  return (
    <>
      <section>
        <div className="container mx-auto">
          <div class="flex border-b bg-white rounded-md  flex-wrap items-center">
            <div class="w-full lg:w-1/2 p-6">
              <div class="lg:max-w-lg">
                <h2 class="mb-6 font-heading font-bold text-5xl text-gray-900">
                  UI / UX Designing Service
                </h2>
                <p class="mb-12 text-lg text-gray-600">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Porro quisquam enim necessitatibus veritatis eos impedit ipsa
                  libero maxime asperiores, delectus illo adipisci harum tempore
                  aspernatur.
                </p>
                <button
                  onClick={() => {
                    navigate("/contact-us");
                  }}
                  className="bg-[#E50403] text-white p-2 px-5 rounded-md"
                >
                  Contact us
                </button>
              </div>
            </div>
            <div class="w-full lg:w-1/2 p-6">
              <div class="bg-gradient-orange flex justify-center md:justify-end  rounded-3xl">
                <img
                  class="relative md:h-96 rounded  transform transition ease-in-out duration-500"
                  src="https://miro.medium.com/max/1400/1*Y1OLX39lJUt_CC-F7rFg4A.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          {/* Second Section */}
          <div className="flex space-y-6 mt-20 flex-col items-center">
            <h2 className="text-4xl font-semibold">UI / UX Design Features</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere
              molestiae ad quia eveniet laboriosam magnam eaque deleniti quaerat
              quo voluptate?
            </p>
            <div className="grid py-12 gap-4 grid-cols-3">
              <div className="flex shadow-xl p-4 border rounded-lg justify-between space-y-4 flex-col">
                <img
                  src="https://softobiz.com/wp-content/uploads/2019/12/how-important-uiux-V2-2.jpg"
                  alt=""
                />
                <h2 className="text-2xl font-semibold">
                  Lorem ipsum dolor sit amet consectetur adipisicing.
                </h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Voluptatum alias ea quas ratione debitis. Vero eaque numquam
                  ea amet maiores.
                </p>
              </div>
              <div className="flex shadow-xl p-4 border rounded-lg justify-between space-y-4 flex-col-reverse">
                <img
                  src="https://softobiz.com/wp-content/uploads/2019/12/how-important-uiux-V2-2.jpg"
                  alt=""
                />
                <div className="">
                  <h2 className="text-2xl font-semibold">
                    Lorem ipsum dolor sit amet consectetur adipisicing.
                  </h2>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Voluptatum alias ea quas ratione debitis. Vero eaque numquam
                    ea amet maiores.
                  </p>
                </div>
              </div>
              <div className="flex shadow-xl p-4 border rounded-lg justify-between space-y-4 flex-col">
                <img
                  src="https://softobiz.com/wp-content/uploads/2019/12/how-important-uiux-V2-2.jpg"
                  alt=""
                />
                <h2 className="text-2xl font-semibold">
                  Lorem ipsum dolor sit amet consectetur adipisicing.
                </h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Voluptatum alias ea quas ratione debitis. Vero eaque numquam
                  ea amet maiores.
                </p>
              </div>
              <div className="flex shadow-xl p-4 border rounded-lg justify-between space-y-4 flex-col">
                <img
                  src="https://softobiz.com/wp-content/uploads/2019/12/how-important-uiux-V2-2.jpg"
                  alt=""
                />
                <h2 className="text-2xl font-semibold">
                  Lorem ipsum dolor sit amet consectetur adipisicing.
                </h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Voluptatum alias ea quas ratione debitis. Vero eaque numquam
                  ea amet maiores.
                </p>
              </div>
              <div className="flex shadow-xl p-4 border rounded-lg justify-between space-y-4 flex-col-reverse">
                <img
                  src="https://softobiz.com/wp-content/uploads/2019/12/how-important-uiux-V2-2.jpg"
                  alt=""
                />
                <div className="">
                  <h2 className="text-2xl font-semibold">
                    Lorem ipsum dolor sit amet consectetur adipisicing.
                  </h2>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Voluptatum alias ea quas ratione debitis. Vero eaque numquam
                    ea amet maiores.
                  </p>
                </div>
              </div>
              <div className="flex shadow-xl p-4 border rounded-lg justify-between space-y-4 flex-col">
                <img
                  src="https://softobiz.com/wp-content/uploads/2019/12/how-important-uiux-V2-2.jpg"
                  alt=""
                />
                <h2 className="text-2xl font-semibold">
                  Lorem ipsum dolor sit amet consectetur adipisicing.
                </h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Voluptatum alias ea quas ratione debitis. Vero eaque numquam
                  ea amet maiores.
                </p>
              </div>
            </div>
          </div>
          <div className="space-y-10 py-20">
            <h2 className="text-4xl font-semibold ">
              I've Worked on Projects for
            </h2>
            <div className="flex justify-between">
              {img.map((e) => {
                return <img className="w-56" src={e.image} alt="" />;
              })}
            </div>
            <div className="">
              <h3>
                I've been Designing and building websites for 18 Years, you can
                view more projects{" "}
                <span
                  onClick={() => {
                    navigate("/portfolio");
                  }}
                >
                  {" "}
                  here.
                </span>
              </h3>
            </div>
          </div>
          {/* Contact us */}
          <ContactUs />
        </div>
      </section>
    </>
  );
};

const img = [
  {
    image: "https://gobeens.com/wp-content/uploads/2021/07/Gobeens-3.png",
  },
  {
    image: "https://gobeens.com/wp-content/uploads/2021/07/Gobeens-3.png",
  },
  {
    image: "https://gobeens.com/wp-content/uploads/2021/07/Gobeens-3.png",
  },
  {
    image: "https://gobeens.com/wp-content/uploads/2021/07/Gobeens-3.png",
  },
  {
    image: "https://gobeens.com/wp-content/uploads/2021/07/Gobeens-3.png",
  },
];

export default UIUX;
