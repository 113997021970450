import React, { useState } from "react";
import Logo from "../Assets/Logo.png";
import { Link, useNavigate } from "react-router-dom";
import { MdMenu, MdClose } from "react-icons/md";
import Social_Links from "./Social_Links";
import { AiOutlineClockCircle } from "react-icons/ai";

const Navbar = () => {
  const [menu, setMenu] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      {/* Normal Headline */}
      {/* <section className="bg-[rgb(49,170,183)]  mb-[1px] shadow-lg   flex items-center ">
        <div className="justify-between p-3 md:px-6 flex items-center container mx-auto">
          <div className="tracking-wide flex gap-1 md:gap-2 items-center">
            <AiOutlineClockCircle className="md:text-lg text-sm font-semibold" />
            <span className="text-xs md:text-sm">
              11:00 AM - 6:00 PM Mon - Fri
            </span>
          </div>

          <div className="flex gap-2.5 md:gap-4">
            {Social_Links.map((icons, index) => {
              return (
                <a
                  key={index}
                  href={icons.path}
                  className="text-white hover:scale-125 transition-all font-medium text-sm md:text-lg"
                >
                  {icons.icon}
                </a>
              );
            })}
          </div>
        </div>
      </section> */}
      {/* Main Header */}
      <section className="bg-white   mb-[1px] shadow-lg md:text-white  flex items-center ">
        <div className="justify-between p-3 md:px-6 flex items-center container mx-auto">
          {/* Logo */}
          <Link to="/">
            <img src={Logo} className="md:w-28 w-24" alt="USD SERVICE" />
          </Link>
          {/* Navitems */}
          <nav
            className={`flex md:flex-row flex-col absolute ${
              menu ? "top-0" : "-top-full"
            } transition-all text-black  duration-150  z-50 md:z-auto left-0 md:bg-transparent  bg-slate-50 h-auto md:h-auto md:static  md:justify-center font-medium shadow-md md:shadow-none p-5 px-4 md:p-0 w-full md:w-auto gap-3  md:gap-6 tracking-wide uppercase`}
          >
            {/* Close Button */}
            <div className="flex md:hidden  w-full justify-end">
              <MdClose
                onClick={() => setMenu(false)}
                className=" text-3xl bg-white p-1 rounded-full shadow-xl hover:rotate-90 transition-all  cursor-pointer "
              />
            </div>
            <div
              onClick={() => {
                navigate("/");
                setMenu(false);
              }}
              className={link_hover}
            >
              Home
            </div>
            <div
              onClick={() => {
                navigate("/about-us");
                setMenu(false);
              }}
              className={link_hover}
            >
              About
            </div>
            <div
              onClick={() => {
                navigate("/service");
                setMenu(false);
              }}
              className={link_hover}
            >
              Service
            </div>
            <div
              onClick={() => {
                navigate("/our-client");
                setMenu(false);
              }}
              className={link_hover}
            >
              Our Client
            </div>
            <div
              onClick={() => {
                navigate("/blog");
                setMenu(false);
              }}
              className={link_hover}
            >
              Blog
            </div>
            <div
              onClick={() => {
                navigate("/career");
                setMenu(false);
              }}
              className={link_hover}
            >
              Career
            </div>
            <button
              onClick={() => {
                navigate("contact-us");
                setMenu(false);
              }}
              className={`${button_style} md:hidden`}
            >
              Contact Us
            </button>
          </nav>
          {/* Button Contact */}
          <button
            onClick={() => navigate("contact-us")}
            className={`${button_style} md:inline-block hidden`}
          >
            Contact Us
          </button>

          {/* Hamburger Icon */}
          <MdMenu
            onClick={() => setMenu(true)}
            className="text-black text-3xl cursor-pointer md:hidden"
          />
        </div>
      </section>
    </>
  );
};

const link_hover =
  "hover:bg-white cursor-pointer md:hover:text-[#E50403] md:hover:bg-transparent  md:hover:shadow-none md:py-0 md:text-start hover:shadow-lg rounded-sm text-sm py-1.5 text-center";
const button_style =
  "py-2 px-4 text-xs  w-auto  tracking-widest bg-[#E50403] rounded-md hover:bg-[#261947] text-white uppercase rounded-sm font-medium  transition-all duration-100";

export default Navbar;
