import React from "react";
import { useNavigate } from "react-router-dom";

const GreenUSD = () => {
  const navigate = useNavigate();
  return (
    <>
      <section>
        <div className="container mx-auto">
          <div class="flex border-b bg-white rounded-md  flex-wrap items-center">
            <div class="w-full lg:w-1/2 p-6">
              <div class="lg:max-w-lg">
                <h2 class="mb-6 font-heading font-bold text-5xl text-gray-900">
                  Ayurvedic Medicine Mini Nursery
                </h2>
                <p class="mb-12 text-lg text-gray-600">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Porro quisquam enim necessitatibus veritatis eos impedit ipsa
                  libero maxime asperiores, delectus illo adipisci harum tempore
                  aspernatur.
                </p>
                <button
                  onClick={() => {
                    navigate("/contact-us");
                  }}
                  className="bg-[#E50403] text-white p-2 px-5 rounded-md"
                >
                  Contact us
                </button>
              </div>
            </div>
            <div class="w-full lg:w-1/2 p-6">
              <div class="bg-gradient-orange flex justify-center md:justify-end  rounded-3xl">
                <img
                  class="relative md:h-96 rounded  transform transition ease-in-out duration-500"
                  src="https://www.financialexpress.com/wp-content/uploads/2022/02/AYUSH-Pic-1.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
          {/* Second Section */}
          <div className="md:flex p-6 space-y-5 md:space-y-0 md:my-40 items-center justify-between">
            <div className="md:w-2/4 space-y-6">
              <h2 className="text-4xl font-semibold">Ayurvedic Midicine</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam
                provident incidunt magnam repellat ratione placeat, laudantium
                dolorem praesentium eveniet enim in nisi tenetur a commodi
                doloremque possimus repellendus nulla aut autem repudiandae
                quibusdam! Laudantium reiciendis rerum qui, distinctio
                blanditiis et.
              </p>
              <button className="bg-[#E50403] p-2 px-5 rounded-md text-white">
                Get Started
              </button>
            </div>
            <div className="md:w-2/4 flex md:justify-end">
              <img
                className="md:w-3/4"
                src="https://www.financialexpress.com/wp-content/uploads/2022/02/AYUSH-Pic-1.jpg"
                alt=""
              />
            </div>
          </div>
          <hr />
          {/* Third Section */}
          <div className="md:flex p-6 space-y-5 md:space-y-0 md:my-40 items-center justify-between">
            <div className="md:w-2/4 flex justify-start">
              <img
                className="md:w-3/4"
                src="https://www.thestatesman.com/wp-content/uploads/2020/01/ayurveda.jpg"
                alt=""
              />
            </div>
            <div className="md:w-2/4 space-y-6">
              <h2 className="text-4xl font-semibold">Wind Turbine</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum,
                culpa! Animi autem nemo eos qui, error asperiores rem placeat
                doloremque! Tempora officia pariatur laboriosam debitis, iusto
                illum consequuntur adipisci sapiente veniam fugit necessitatibus
                aliquid vitae voluptatibus eius error, a perferendis! Neque
                inventore, beatae nemo corporis voluptatem quo obcaecati
                doloremque rem.
              </p>
              <button className="bg-[#E50403] p-2 px-5 rounded-md text-white">
                Get Started
              </button>
            </div>
          </div>
          <hr />
          {/* Fourth Section */}
          <div className="md:flex p-6 space-y-5 md:space-y-0 md:my-40 items-center justify-between">
            <div className="md:w-2/4 space-y-6">
              <h2 className="text-4xl font-semibold">Import Export</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam
                provident incidunt magnam repellat ratione placeat, laudantium
                dolorem praesentium eveniet enim in nisi tenetur a commodi
                doloremque possimus repellendus nulla aut autem repudiandae
                quibusdam! Laudantium reiciendis rerum qui, distinctio
                blanditiis et.
              </p>
              <button className="bg-[#E50403] p-2 px-5 rounded-md text-white">
                Get Started
              </button>
            </div>
            <div className="md:w-2/4 flex md:justify-end">
              <img
                className="md:w-3/4"
                src="https://www.financialexpress.com/wp-content/uploads/2022/02/AYUSH-Pic-1.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GreenUSD;
