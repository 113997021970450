import React from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "./Layout/Footer";

// Components
import Navbar from "./Layout/Navbar";
import About from "./Pages/About/About";
import Blog from "./Pages/Blog/Blog";
import BlogRead from "./Pages/Blog/BlogRead";
import Career from "./Pages/Career/Career";
import ContactUs from "./Pages/ContactUs/ContactUs";
import Home from "./Pages/Home/Home";
import Client from "./Pages/OurClient/Client";
import DigitalMarketing from "./Pages/Services/DigitalMarketing";
import FleetManage from "./Pages/Services/FleetManage";
import GreenUSD from "./Pages/Services/GreenUSD";
import HackedSiteClean from "./Pages/Services/HackedSiteClean";
import Hosting from "./Pages/Services/Hosting";
import Services from "./Pages/Services/Services";
import UIUX from "./Pages/Services/UIUX";
import WebDevelopment from "./Pages/Services/WebDevelopment";

const App = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:title" element={<BlogRead />} />
        <Route path="/service" element={<Services />} />
        <Route path="/web-development" element={<WebDevelopment />} />
        <Route path="/site-hacked" element={<HackedSiteClean />} />
        <Route path="/ui-ux" element={<UIUX />} />
        <Route path="/green-usd" element={<GreenUSD />} />
        <Route path="/fleet-manage" element={<FleetManage />} />
        <Route path="/web-hosting" element={<Hosting />} />
        <Route path="/digital-marketing" element={<DigitalMarketing />} />

        <Route path="/about-us" element={<About />} />
        <Route path="/our-client" element={<Client />} />
        <Route path="/career" element={<Career />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
